import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {exists: true}
  },
  {
    path: '/katalog',
    name: 'Katalog',
    component: () => import('../views/Catalog.vue'),
    meta: {exists: true}
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('../views/Contact.vue'),
    meta: {exists: true}
  },
  {
    path: '/szkla',
    name: 'Szkla',
    component: () => import('../views/CatalogGlasess.vue'),
    meta: {exists: true}
  },
  {
    path: '/wklady',
    name: 'Wklady',
    component: () => import('../views/CatalogWklady.vue'),
    meta: {exists: true}
  },
  {
    path: '/ogrzewacze',
    name: 'heaters',
    component: () => import('../views/Heaters.vue'),
    meta: {exists: true}
  }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  }
})

router.beforeEach((to, _, next) => {
  if (to.meta.exists) {
    next(true)
  }
  else {
    next('/')
  }
})

export default router
