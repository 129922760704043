<template>
  <div>
    <div class="body-content">
      <Banner />
      <About />
      <ContentElement id="glasses"
        image-name="glass.png"
        background-color="#f1f1f1" 
        :row-reverse="false"
        router-to="/szkla"
        class="content-element"
      >
        <template v-slot:title>
           SZKŁA DO LUSTEREK
        </template>
        <template v-slot:content> 
          W naszej ofercie mogą znaleźć pańśtwo najlepsze na rynku dostępne szkła do lusterek samochodowych oraz motocyklowych. W zestawie szkła posiadają specjalną taśme do przyklejenia w plastik montażowy (tylko w modelach gdzie jest wymagana do montażu). 
          W zależności od indywidualnych potrzeb użytkowników oferujemy:
          <ul style="margin-top: 1rem">
            <li> <b> Szkła płaskie </b> - Najprostsze szkła, których podstawową cechą jest to, że obraz nie jest przybliżony. Montowane są fabrycznie w niektórych lusterkach samochodowych. </li>
            <li> <b> Szkła sferyczne </b> - Wykonane są z tafli białego szkła w kształcie wycinka kuli, dzięki czemu powiększają pole widzenia. Szkła te występują w większości dostępnych na rynku samochodów. </li>
            <li> <b> Szkła asferyczne </b> - Szkła znacząco powiększające pole widzenia dzięki wyprofilowaniu skrajnej części szkła. Niwelują zjawisko martwego pola. </li>
          </ul>
          Każde szkła dodatkowo produkujemy w dwóch wersjach: <b> białe </b> oraz <b> niebieskie </b>.
        </template>

      </ContentElement>
      <ContentElement id="heaters"
        image-name="heater.png"
        background-color="#ffffff" 
        :row-reverse="true"
        router-to="/ogrzewacze"
        class="content-element"
      >
        <template v-slot:title>
           PODGRZEWACZE
        </template>
        <template v-slot:content> 
          Dostarczaramy również najwyższej jakości rozwiązania grzewcze. Podgrzewacz do lusterek samochodowych to element, który <b> ogrzewa</b>, <b> odmraża </b> i <b>wysusza</b> nam nasze standardowe lusterka w samochodzie.
          Jest to wysokiej jakości samoprzylepna folia grzejąca - montowana od wewnętrznej strony wkładu lusterka, taka sama jak w samochodach najwyższej klasy. Zamontować ją sobie trzeba samemu, ale koszty są <b> dziesięciokrotnie </b> niższe niż zakup fabrycznych podgrzewanych lusterek. 
        </template>

      </ContentElement>
      <ContentElement id="wklady"
        image-name="wklad.png"
        background-color="#f1f1f1f1"
        :row-reverse="false"
        router-to="/wklady"
        class="content-element"
      >
        <template v-slot:title>
           WKŁADY MONTAŻOWE
        </template>
        <template v-slot:content> 
          Dla sporej większości samochodów oferujemy gotowe wkłady do lusterek samochodywch z możliwością wyboru rodzaju szkła. W skład wkładu wchodzą:
          <ul style="margin-top: 1rem">
            <li> <b> Płytka montażowa </b> </li>
            <li> <b> Dowolnie wybrane szkło </b> </li>
            <li> <b> Mata grzewcza  </b> </li>
          </ul>
          Gotowy wkład wystarczy umiejscowić w lusterku samochodwym, a następnie podłączyć przewody zasilające podgrzewacz. 
        </template>

      </ContentElement>
    
    </div>

  </div>
</template>

<script>


import About from '@/components/About.vue'
import Banner from '@/components/Banner.vue'
import ContentElement from '@/components/ContentElement.vue'
import { useHead } from '@vueuse/head'
export default {
  name: 'Home',
  components: {
    Banner,
    About,
    ContentElement
  },
  data() {
    return {
      meta: useHead({
      // Can be static or computed
      title: 'Lusterka Adam',
      meta: [
        {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {name: 'description', content: 'P.P.H.U. Import Eksport ADAM jest producentem części do lusterek samochodowych działającycm aktywnie na rynku od poand 20lat. W ofercie znajdują się szkła do lusterek, wkłady oraz ogrzewacze.'},
        {name: 'keywords', content: 'Lusterka, Szkła do lustrerek, Ogrzewacze, Wkłady, Szkła'}
      ]
    })
    }
  },

}
</script>

<style>
.body-content {
  font-size: clamp(1.5rem, 2.3vw, 2rem);
  max-width: 100vw;
}
.content-element {
  padding: 0 0.1rem;
}
@media (max-width: 600px) {
  ul {
    padding-left: 1rem !important;
  }
}
</style>
