<template>
    <div class="banner-container">
    </div>
</template>

<style scoped>
.banner-container {
    background-image: url('../assets/banner5.jpg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position-y: 60%;
    height: calc(0.278 * 100vw);
}


</style>