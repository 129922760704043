<template>
    <div class="d-flex flex-column h-100">

    <!-- FOOTER -->
    <footer class="w-100 py-4 flex-shrink-0">
        <div class="container py-4">
            <div class="row gy-4 gx-5">
                <div class="col-lg-4 col-md-6">
                    <h5 class="h1 text-white">Lusterka <span class="colored"> Adam </span></h5>
                    <p class="small text-muted">P.P.H.U. Import Eksport ADAM jest producentem części do lusterek samochodowych działającycm aktywnie na rynku od 1998 roku.</p>
                    <p class="small text-muted mb-0">&copy; Copyrights. Wszelkie prawa zastrzeżone. Lusterka-Adam </p>
                    <p class="small text-muted mb-0">&copy; Część Ikon została wykorzystana z serwisu Flaticon.com </p>
                </div>
                <div class="col-lg-2 col-md-6">
                    <h5 class="text-white mb-3 bordered">Nasze produkty</h5>
                    <ul class="list-unstyled text-muted">
                        <li><a href="#glasses">Szkła do lusterek</a></li>
                        <li><a href="#wklady">Wkłady montażowe</a></li>
                        <li><a href="#heaters">Podgrzewacze</a></li>
                    </ul>
                </div>
                <div class="col-lg-2 col-md-6">
                    <h5 class="text-white mb-3 bordered">Nawigacja</h5>
                    <ul class="list-unstyled text-muted">
                        <li><a href="#navbar">Strona główna</a></li>
                        <li><a href="#glasses">Oferta</a></li>
                        <li><a href="/katalog">Katalogi</a></li>
                        <li><a href="/kontakt">Kontakt</a></li>
                    </ul>
                </div>
                <div class="col-lg-4 col-md-6">
                    <h5 class="text-white mb-3 bordered">Kontakt</h5>
                    <ul class="list-unstyled text-muted">
                        <li><i class="pi pi-map"></i>Mała Góra 29, 30-864 Kraków</li>
                        <li><i class="pi pi-phone"></i><a href="tel:126578240">12 657 82 40</a>, <a href="tel:+48606199107"> +48 606 199 107 </a></li>
                        <li><i class="pi pi-envelope"></i>lusterka@e.pl</li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</div>
</template>

<script>

</script>

<style scoped>
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s;
}
a {
    transition: all .1s;
}
a:hover {
    color: var(--orange10) !important;
    cursor: pointer;
}
.bordered {
    border-bottom: 1px var(--orange10) solid;
}
ul > * > i {
    margin-right: 1em;
    font-size: 1.3em;
}
a:hover, a:focus {
  text-decoration: none;
}
.list-unstyled > * > a:hover {
    color: var(--orange10) !important;
}
.form-control {
  background: #212529;
  border-color: #545454;
}

.form-control:focus {
  background: #212529;
}

footer {
  background: #212529;
}
.colored {
    color: var(--orange10)
}


</style>