<template>
    <div class="title">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            txt: String
        }
    }
</script>

<style scoped>
.title {
    font-size: clamp(0.6em, 4vw, 1em);
    margin-bottom: 1em;
}
</style>