<template>
    <div class="navbar-wrapper">
        <div class="logo-wrapper">
            <img @click="routerTo('/')" class="logo" src="@/assets/logo.png">
        </div>
        <div class="menu-wrapper font-libre">
            <span @click="routerTo('/')">Strona główna</span> <b> | </b>
             <span><a href="/#glasses"> Oferta </a></span> <b> | </b>
            <span @click="routerTo('/katalog')">Katalogi</span> <b> | </b>
            <span @click="routerTo('/kontakt')">Kontakt</span>
        </div>
        <div class="dropdown-wrapper">
            <Button @click="showDropdown()" icon="pi pi-bars" class="menu-button p-button-rounded p-button-secondary" />
                <transition name="dropdown">    
                    <div v-if="dropdown" class="listbox">
                        <Listbox v-model="selectedCity" :options="cities" optionLabel="name" style="width:10rem">
                            <template #option="slotProps">
                                <div @click="routerToMobile(slotProps.option.name)">
                                    <span>{{slotProps.option.name}}</span>
                                </div>
                            </template>
                        </Listbox>
                    </div>
                </transition>
        </div>
    </div>
</template>

<script>
    import Button from 'primevue/button';
    import Listbox from 'primevue/listbox';
    export default {
        components: {
            Button,
            Listbox
        },
        data() {
            return {
                dropdown: false,
                cities: [
                {name: 'Strona główna', code: 'NY'},
                {name: 'Oferta', code: 'RM'},
                {name: 'Katalogi', code: 'LDN'},
                {name: 'Kontakt', code: 'IST'},
            ],
            }
        },
        methods: {
            routerTo(to) {
                this.$router.push(to)
            },
            routerToMobile(to) {
                const element = document.getElementById('glasses')
                switch (to) {
                    case 'Strona główna':
                        this.$router.push('/')
                        break;
                    case 'Oferta':
                        element.scrollIntoView()
                        break;
                    case 'Katalogi':
                        this.$router.push('/katalog')
                        break;
                    case 'Kontakt':
                        this.$router.push('/kontakt')
                        break;
                    default:
                        this.$router.push('/')
                        break;
                }
                this.dropdown = false
            },
            showDropdown() {
                this.dropdown = !this.dropdown
            }
        },
    }
</script>

<style scoped>
a:visited, a:link, a:link:active, a:visited:active{
    background-color: unset;
    text-decoration: unset;
}
a {
    color: var(--grey20);
}
a:hover {
    color: var(--orange10);
    text-decoration: unset;
}
.navbar-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 5rem;
    align-items: center;
}
.logo {
    padding: 0.4em;
    width: clamp(120px, 12vw, 11rem)
}
.dropdown-wrapper {
    margin-right: 3rem;
    visibility: hidden;
}
.menu-button {
    background-color: var(--orange10) !important;
    border-color: var(--orange10) !important;
    color: white;
}
.menu-wrapper {
    position: absolute;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    color: var(--grey20) !important;
    font-size: clamp(10px,2vw,1.2rem);
}
@media (max-width: 700px) {
    .navbar-wrapper{
        justify-content: space-between;
    }
    .menu-wrapper {
        visibility: hidden;
    }
    .dropdown-wrapper {
        visibility:unset;
    }
}
.menu-wrapper > span {
    margin: 0 1em 0 1em;
    color: rgb(58, 58, 58);
    transition: all .2s;
}
.menu-wrapper > span:hover {
    color: var(--orange10) !important;
    cursor: pointer !important;
}
.listbox {
    position: absolute !important;
    z-index: 999;
    right: 1rem;
}
.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 0.5s ease;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
}

.dropdown-enter-to,
.dropdown-leave-from {
    opacity: 1;
}
b {
    color: var(--orange10) !important
}
.logo:hover {
    cursor: pointer;
    z-index: 999;
}
</style>