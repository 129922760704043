<template>
  <div>
    <Navbar id="navbar" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { useHead } from '@vueuse/head'
export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      meta: useHead({
      // Can be static or computed
      title: 'Lusterka Adam',
      meta: [
        {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {name: 'description', content: 'P.P.H.U. Import Eksport ADAM jest producentem części do lusterek samochodowych działającycm aktywnie na rynku od poand 20lat. W ofercie znajdują się szkła do lusterek, wkłady oraz ogrzewacze.'},
        {name: 'keywords', content: 'Lusterka, Szkła do lustrerek, Ogrzewacze, Wkłady, Szkła'}
      ]
    })
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');
:root {
  --orange10: #ff7518;
  --orange20: #f54703;
  --grey10: #464545;
  --grey20: #2f2f2f;
  --black10: #1b1b1b;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  max-width: 100vw;
}
.font-libre {
  font-family: 'Libre Baskerville', serif;
}
.p-inputtext:enabled:focus {
  border-color: var(--orange20) !important;
  box-shadow: 0 0 0 0.2rem var(--orange10) !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--orange20) !important;
  box-shadow: 0 0 0 0.2rem var(--orange10) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: var(--orange10) !important;
    color: black !important;
}
.p-inputtext:hover, .p-dropdown:hover {
  border-color: var(--orange20) !important;
}
.p-button:hover {
  background-color: rgba(255, 136, 0, 0.151) !important;
}
.p-button:focus {
  box-shadow: 0 0 0 0.2rem var(--orange10) !important;
}
.p-button:enabled:active {
  background-color: rgba(255, 193, 77, 0.801) !important;
}
.p-listbox-item:focus {
  box-shadow: 0 0 0 0.2rem var(--orange10) !important;
}
b {

}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  background: var(--orange10) !important;
}
</style>
