<template>
    <div class="about-wrapper font-libre">
        <Title class="title"> O NAS </Title>
        <div class="about-content">
            P.P.H.U. Import Eksport ADAM jest producentem części do lusterek samochodowych działającycm aktywnie na rynku od 1998 roku. Wybierając nasze produkty wspierasz polskiego producenta
            stawiającego na pierwszym miejscu precyzje oraz dokładność.
            W naszej ofercie znajdziecie państwo między innymi: 
                <b>szkła do lusterek</b>, 
                <b>kompletne podgrzewane wkłady</b> oraz 
                <b>podgrzewacze do lusterek</b>
            Wszystkie wyżej wymienione elementy produkujemy dla samochodów osobowych, dostawczych oraz jednośladów. Oferujemy również <b> usługę montażu na miejscu</b>. Zapraszamy do zapoznania się z naszą ofertą. :)
        </div>
    </div>
</template>


<style scoped>
.about-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 18rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
}
.about-content {
    font-size: 0.5em;
    max-width: 60em;
    padding: 1rem 2rem;
    text-justify: inter-word;
    line-height: 1.7;
}
.title {
    font-size: 1em;
    color: var(--orange);
}
b {

}
</style>