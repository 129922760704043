<template>
    <div class="glasses-wrapper font-libre" :style="{'background-color': backgroundColor, 'flex-direction': rowReverse ? 'row-reverse' : 'row'}">
        <div class="glasses-left">
            <img :src="require('@/assets/' + imageName + '')" alt="">
            <div class="btn">
                <Button @click="navigateTo(routerTo)" class="p-button-outlined button-button" label="Zobacz katalog" />
            </div>
        </div>
        <div class="glasses-right">
            <Title> 
                <slot name="title"></slot>
            </Title>
            <div class="glasses-right-content">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>
<script>
import Button from 'primevue/button';
export default {
    props: {
        imageName: String,
        backgroundColor: String,
        rowReverse: Boolean,
        routerTo: String
    },
    components: {
        Button
    },
    methods: {
        navigateTo(to) {
            this.$router.push(to)
        }
    },
}

</script>
<style scoped>
.glasses-wrapper {
    display: flex;
    align-items: center;
    min-height: 35rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    line-height: 1.7;
}
.glasses-wrapper > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.2em;
    width: 50%;
}
.glasses-left > img {
    object-fit: contain;
    max-width: clamp(18rem, 35vw, 30rem);
    max-height: 15rem;
}
.title {
    font-size: 1em;
    color: var(--orange);
}
.glasses-right-content {
    text-align: justify;
    text-justify: inter-word;
    font-size: 0.5em;
    padding: 0 1.1em 0 1.1em;
}
.p-button {
    border-color: var(--orange10) !important;
    color: var(--orange10) !important;
}
.button-button {
    width: 15em;
    height: 2.5em;
}
.btn {
    margin-top: 1em;
    padding-bottom: 1em;
}

@media (max-width: 900px) {
    .glasses-wrapper {
        flex-direction: column-reverse !important;
    }
    .glasses-wrapper > * {
        margin: 1em;
        width: 80%;
        
    }
}
</style>