import { createApp } from 'vue'
import App from './App.vue'
import Title from '@/components/Title.vue'
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/saga-blue/theme.css"
import "primevue/resources/primevue.min.css"
import "/node_modules/primeflex/primeflex.css"
import "primeicons/primeicons.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import router from './router'
import ToastService from 'primevue/toastservice';
import { createHead } from '@vueuse/head'

const head = createHead()

const app = createApp(App).use(router);
app.component('Title', Title)
app.use(PrimeVue).use(ToastService).use(head)
app.mount('#app')
